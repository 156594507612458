<template>
    <div>
        <el-row type="flex" class="row-bg mainContent" justify="space-between">
            <el-col :span="14" class="mainLeft">
                <div class="title">
                    <div :class="$i18n.locale==='zh'?'slogan':'slogan sloganE'" v-html="$t('login.slogan1')"></div>
                    <span>{{ $t('login.slogan2') }}</span>
                </div>
                <div class="logo bgCommon"></div>
            </el-col>
            <el-col :span="10" class="mainRight">
                <div class="main">
                    <h1 class="logo" @click="$router.replace({path:'/'})">
                        <img src="@/assets/images/capital/bigLogo.png" alt=""/>
                    </h1>
                    <div class="title" style=" color: #333; margin-bottom: 20px; font-size: 26px;">
                        {{ $t('login.signUp') }}
                    </div>
                    <div class="form-mobile login-area">
                        <div class="from">
                            <div :class="form.loginNamemsg ? 'form-item form-item-error' : 'form-item'">
                                <label><img src="@/assets/images/login/user.png"/></label>
                                <input
                                    type="text"
                                    v-model="form.loginName"
                                    :placeholder="placeholder"
                                    autocomplete="off"
                                    @blur="slideShow"
                                />
                                <p class="error" v-if="form.loginNamemsg">
                                    <img src="@/assets/images/login/wrong.png"/>
                                    {{ form.loginNamemsg }}
                                </p>
                            </div>
                            <Verification @verification="verification" ref="Verification" v-show="slide" />
                            <div :class="form.codemsg ? 'yzm-item form-item-error' : 'yzm-item'">
                                <div class="form-item">
                                    <label><img src="@/assets/images/login/email.png" style="width: 22px; height: 23px"/></label>
                                    <input type="text" v-model="form.code" autocomplete="off" :placeholder="$t('login.code')"/>
                                    <p class="error" v-if="form.codemsg">
                                        <img src="@/assets/images/login/wrong.png"/>
                                        {{ form.codemsg }}
                                    </p>
                                </div>
                                <span class="yzmbtn" :disabled="disabled" @click="getVerificationCode()">{{ yzmtext }}</span>
                            </div>
                            <div :class="form.passwordmsg ? 'form-item form-item-error' : 'form-item'" style="margin-top: 35px">
                                <label><img src="@/assets/images/login/password.png"/></label>
                                <input type="password" v-model="form.password" :placeholder="$t('login.password')" autocomplete="off" @blur="ispassword" />
                                <p class="error" v-if="form.passwordmsg">
                                    <img src="@/assets/images/login/wrong.png"/>{{ form.passwordmsg }}
                                </p>
                            </div>
                            <div class="read">
                                <input type="checkbox" v-model="checked" class="checkbox" id="checkbox"/>
                                <label for="checkbox" class="checkboxlabel">
                                    <span class="checkboxspan" v-if="checked">
                                        <img src="@/assets/images/login/checkbox1.png"/>
                                    </span>
                                    <span class="checkboxspan" v-else>
                                        <img src="@/assets/images/login/checkbox2.png"/>
                                    </span>
                                </label>
                                <label for="read">
                                    {{ $t('login.read') }}
                                    <a :href="regAgreement" target="_blank">{{ $t('login.regAgreement') }}</a>
                                    {{ $t('login.and') }}
                                    <a :href="privacyAgreement" target="_blank">{{ $t('login.privacyAgreement') }}</a>
                                </label>
                            </div>
                            <div class="form-btn" @click="loginTo" style="margin-top:34px">
                                <span>{{ $t('login.goLogin2') }}</span>
                            </div>
                            <div class="gologin" @click="goLogin">
                                <span>{{ $t('login.goLogin') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import qs from "qs";
var interval = null;
import Verification from "@/components/Verification/loginV";
import {signupApi, sendMsgApi} from "@/api/service";
import { urlDelP} from '@/utils/common';
import md5 from 'js-md5'

export default {
    data() {
        return {
            deviceType:'PC',
            regAgreement:process.env.VUE_APP_USERCENTERUI+'/agreement/registration',
            privacyAgreement:process.env.VUE_APP_USERCENTERUI+'/agreement/privacy',
            active: 0,
            checked: false,
            form: {
                loginName: "",
                loginNamemsg: "",
                password: "",
                passwordmsg: "",
                code: "",
                codemsg: "",
                inviteCode: "",
            },
            clientId: 952310,
            signupType: "PHONE",
            disabled: false,
            currentTime: 60,
            verify: false,
            slide: false,
            nvc: "",
            yzmtext:this.$t("login.getCode"),
            placeholder:this.$t("login.userName2")
        };
    },

    components: {Verification},

    computed: {},

    created() {},

    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize)
    },
    deactivated () {
        window.removeEventListener('resize', this.resize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize)
    },

    methods: {
        resize () {
            if (document.body.clientWidth > 768) {
                this.deviceType = 'PC';
            } else {
                this.deviceType = 'MOBILE';
            }
        },
        slideShow(){
            this.isverify();
            this.slide = true;
            this.disabled = false;
            clearInterval(interval);
            this.yzmtext = this.$t("login.getCode");
            this.currentTime = 60;
        },
        isverify() {
            var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
            let myreg;
            //除了生产环境其他环境可以增加号段测试用。
            if(process.env.VUE_APP_CURRENTMODE=='test' || process.env.VUE_APP_CURRENTMODE=='dev' || process.env.VUE_APP_CURRENTMODE=='pre'){
                myreg =/^1[0123456789]\d{9}$/;
            }else{
                myreg =/^1[3456789]\d{9}$/;
            }
            if (myreg.test(this.form.loginName)) {
                this.active = 0;
                this.signupType = "PHONE";
                this.form.loginNamemsg = '';
            } else if (reg.test(this.form.loginName)) {
                this.active = 1;
                this.signupType = "EMAIL";
                this.form.loginNamemsg = '';
            } else {
                this.active = -1;
                this.signupType = "";
                this.form.loginNamemsg = this.$t('login.verUserName');
            }
        },
        ispassword() {
            if (!/^[A-Za-z0-9]{6,20}$/.test(this.form.password)) {
                this.form.passwordmsg = this.$t('login.verPassword');
                return false;
            } else {
                this.form.passwordmsg = "";
                return true;
            }
        },
        verification(data) {
            if (data) {
                this.nvc = data.nvc;
                this.verify = true;
                this.getVerificationCode();
            }
        },
        async getVerificationCode() {
            this.isverify();
            if (this.active === -2) {
                return false;
            }
            if (this.active === -1) {
                this.form.loginNamemsg = this.$t('login.verUserName');
                return false;
            } else {
                this.form.loginNamemsg = "";
            }
            if (this.slide) {
                if (!this.verify) {
                    this.$diamsg(this.$t('login.varSlide'));
                    return false;
                }
            }
            this.getCode();
            var that = this;
            that.disabled = true;
            var data = qs.stringify({
                signupType: that.signupType,
                loginName: that.form.loginName,
                verifyLoginName: 1,
                nvc: that.nvc
            });
            sendMsgApi(data).then((res) => {
                that.$msg(that.$t('login.sendCode'), "success");
            }).catch((error) => {
                let err=error.data;
                if (err.code === 1 || err.code === 600005) {
                    that.form.loginNamemsg = err.message;
                    that.active = -2;
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t('login.getCode');
                    that.currentTime = 60;
                    that.disabled = false;
                    return false
                }
                if (err.code === 403) {
                    that.$msg(err.message);
                    return false;
                }
                if (err.code === 100 || err.code === 200) {
                    that.$msg(that.$t('login.sendCode'), "success");
                    that.$refs.Verification.resetNvc();
                } else if (err.code === 800 || err.code === 900) {
                    // 无痕验证失败，直接拦截
                    that.$msg(that.$t("login.vError"));
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.verify = false;
                } else{
                    that.$msg(err.message);
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.verify = false;
                    that.$refs.Verification.resetNvc();
                }
            });
        },
        getCode() {
            var that = this;
            var currentTime = that.currentTime;
            interval = setInterval(function () {
                currentTime--;
                that.yzmtext = "(" + currentTime + "s)"+that.$t("login.againGetCode");
                if (currentTime <= 0) {
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                }
            }, 1000);
        },
        loginTo(){
            this.isverify();
            if (this.active === -1) {
                this.form.loginNamemsg = this.$t('login.verUserName');
                return false;
            } else {
                this.form.loginNamemsg = "";
            }

            if (this.active === -2) {
                this.form.loginNamemsg = this.$t('login.alreadyReg');
                return false;
            }

            if (this.slide) {
                if (!this.verify) {
                this.$diamsg(this.$t('login.varSlide'));
                return false;
                }
            }

            if (!this.form.code && !this.form.code.trim()) {
                this.form.codemsg = this.$t('login.code');
                return false;
            } else {
                this.form.codemsg = "";
            }

            if (!this.form.password && !this.form.password.trim()) {
                this.form.passwordmsg = this.$t('login.password');
                return false;
            } else {
                this.form.passwordmsg = "";
            }

            if (!this.ispassword()) {
                return false;
            }

            if (!this.checked) {
                this.$diamsg(this.$t('login.agreement'));
                return false;
            }
            let that = this;
            var params = qs.stringify({
                loginName: that.form.loginName,
                signupType: that.signupType,
                password: md5(that.form.password),
                code: that.form.code,
                inviteCode: that.form.inviteCode,
                clientId: that.clientId,
                partnerType:1,
            });
            if (!that.clientId) {
                delete params.clientId;
            }
            signupApi(params).then((res) => {
                that.$diamsg(this.$t('login.regSuccess'), "success");
                let redirectUrl = that.$getlocalStorage("queryData")? that.$getlocalStorage("queryData").redirectUrl: "";
                if (redirectUrl) {
                    localStorage.removeItem("queryData");
                    if (redirectUrl.indexOf("?") === -1) {
                        window.location.href =decodeURI(redirectUrl) +"?access_token=" +res.access_token+"&language="+this.$getlocalStorage("language")+"&partnerType=1";
                    } else {
                        window.location.href =decodeURI(urlDelP(redirectUrl,'access_token')) +"&access_token=" +res.access_token+"&language="+this.$getlocalStorage("language")+"&partnerType=1";
                    }
                }else{
                    let copyRightUrl;
                    if(this.deviceType == 'PC'){
                        copyRightUrl=process.env.VUE_APP_COPYRIGHT
                    }else{
                        copyRightUrl=process.env.VUE_APP_COPYRIGHT_M
                    }
                    window.location.href = copyRightUrl+"/time/ApplyTime?access_token="+res.access_token+"&language="+this.$getlocalStorage("language")+"&partnerType=1"
                }
            }).catch((error) => {
                let err = error.data;
                if(err.code==600005){
                    that.form.loginNamemsg = err.message;
                    that.active = -2;
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t('login.getCode');
                    that.currentTime = 60;
                    that.disabled = false;
                }else{
                    that.$msg(err.message);
                }
                
            });
        },
        goLogin(){
            this.$router.push({
                path:'/login',
                query: this.$route.query,
            })
        }
    },
};
</script>
<style lang="less" src="@/assets/css/login.less" scoped></style>